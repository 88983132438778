<template>
  <div class="input-group">
    <BaseRadioButton
      v-for="option in options"
      :option="option"
      :key="option"
      :name="name"
      :selectedValue="selectedValue"
    />
  </div>
</template>

<script>
import BaseRadioButton from "./BaseRadioButton.vue";

export default {
  name: "BaseRadioButtonGroup",
  components: {
    BaseRadioButton,
  },
  props: {
    options: {
      required: true,
      type: Array,
    },
    name: {
      required: true,
      type: String,
    },
    selectedValue: {
      required: true,
      type: [String, Number],
    },
  },
};
</script>

<style scoped>
.input-group {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

label {
  cursor: pointer;
}
</style>
