<template>
  <div>
    <label :for="option._id + name" class="radio_label">
      {{ option.name }}
      <input
        type="radio"
        :value="option._id"
        :id="option._id + name"
        @change="$parent.$emit('input', option)"
        :name="name"
        :checked="option._id === selectedValue"
      />
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    selectedValue: {
      required: true,
      type: [String, Number],
    },
  },
};
</script>

<style scoped>
label {
  cursor: pointer;
}
</style>
