<template>
  <div>
    <ValidationObserver ref="business_information">
      <ValidationProvider
        :name="'select' + addressType"
        rules="required"
        v-slot="{ errors }"
      >
        <multiselect
          v-model="licensedStateDetails"
          :value="'_id'"
          :options="usstateList"
          :searchable="true"
          :close-on-select="true"
          :allow-empty="true"
          :multiple="false"
          @input="updateOwnerLicensedState"
          label="name"
          placeholder="Select "
          track-by="name"
        >
        </multiselect>

        <span class="v-messages error-msg frommult" v-if="errors[0]">{{
          errors[0] ? "Field is required" : ""
        }}</span>
      </ValidationProvider>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Multiselect from "vue-multiselect-inv";
import _ from "lodash";
export default {
  name: "phoneInput",
  components: {
    ValidationObserver,
    ValidationProvider,
    Multiselect,
  },
  inject: ["parentValidator"],
  created() {
    this.$validator = this.parentValidator;
  },
  props: {
    masterDataKey: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: 0,
    },
    indexKey: {
      type: String,
      default: "",
    },
    addressType: {
      type: String,
      default: "",
    },
    payLoadKey: {
      type: String,
      default: "",
    },
    licensedState: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      usertdata: null,
      usstateList: [],
      licensedStateDetails: null,
      stateDetails: null,
      usCountry: {
        _id: 231,
        sName: "US",
        name: "United States",
        phoneCode: 1,
        curSymbol: "$",
        curCode: "USD",
        zipLen: 5,
      },
    };
  },
  mounted() {
    this.userdata = this.$store.getters["auth/getuser"];
    if (this.masterDataKey != "") {
      this.getMaterData(this.masterDataKey);
    } else {
      this.getUsStatesList();
    }
  },
  methods: {
    getMaterData(category) {
      let payLoad = {
        matcher: {
          searchString: "",
          //"cntryId": 101,
          //"stateId": 665,
          //"cntryIds": [38], // Requied for "state"
          //"stateIds": [665], // Requied for "city"
        },
        page: 1,
        perpage: 10000000000,
        category: category, // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
      };
      this.$store
        .dispatch("getMaterData", payLoad)
        .then((res) => {
          this.usstateList = res.list;
          if (this.licensedState) {
            this.licensedStateDetails = _.find(this.usstateList, {
              _id: this.licensedState,
            });
            if (this.licensedStateDetails)
              this.$emit("updateOwnerLicensedState", {
                indexKey: this.indexKey,
                stateDetails: this.licensedStateDetails,
                payLoadKey: this.payLoadKey,
                index: this.index,
              });
          }
        })
        .catch((err) => {
          this.log(err);
        });
    },
    getUsStatesList() {
      let payLoad = {
        matcher: {
          searchString: "",
          //"cntryId": 101,
          //"stateId": 665,
          cntryIds: [231], // Requied for "state"
          //"stateIds": [665], // Requied for "city"
        },
        page: 1,
        perpage: 10000000000,
        category: "state", // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
      };
      this.$store.dispatch("getMaterData", payLoad).then((res) => {
        this.usstateList = res.list;
        if (this.licensedState) {
          this.licensedStateDetails = _.find(this.usstateList, {
            _id: this.licensedState,
          });
          if (this.licensedStateDetails)
            this.$emit("updateOwnerLicensedState", {
              indexKey: this.indexKey,
              stateDetails: this.licensedStateDetails,
              payLoadKey: this.payLoadKey,
              index: this.index,
            });
        }
      });
    },
    updateOwnerLicensedState(data) {
      this.licensedStateDetails = data;
      this.$emit("updateOwnerLicensedState", {
        indexKey: this.indexKey,
        stateDetails: this.licensedStateDetails,
        payLoadKey: this.payLoadKey,
        index: this.index,
      });
    },
  },

  computed: {},
  beforeCreate() {
    //console.log(this.showLoading);
  },
};
</script>
